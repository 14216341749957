// THIS VALUES ARE USED DURING CLIENT APP COMPILATION, PLEASE DO NOT MOVE
const initialAppId = 'app_8JRCjbP9EHib6gXykDRUbW'
const initialProjectId = 'proj_16byaHwRhKuVXa331U8q5H'
const initialBuildOrdersOnApp = require('./buildOrders.loaded.json') // eslint-disable-line
const initialFlattedControls = { ...require('./flattedControls.loaded.0.json') } // eslint-disable-line
const initialRunningMode = 'premium'
const initialCustomFontName = undefined
// #################

export const initialAppState = {
    appId: initialAppId,
    projectId: initialProjectId,
    buildOrders: initialBuildOrdersOnApp,
    flattedControls: initialFlattedControls,
    runningMode: initialRunningMode,
    customFontName: initialCustomFontName,
} as const
