import React, { useEffect, useContext } from 'react'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import ControlMapContext from '../ControlMapView/controlMapContext'
import './styles.css'

const ControlMapMarker: AppAtomRenders['MapMarker'] = ({
    title,
    description,
    coordinate,
    children,
}) => {
    const { googleMap, infoWindow } = useContext(ControlMapContext)

    useEffect(() => {
        if (!googleMap || !infoWindow) {
            return
        }

        const latLng = { lat: coordinate.latitude, lng: coordinate.longitude }

        const marker = new google.maps.Marker({
            position: latLng,
            map: googleMap,
        })

        marker.addListener('click', () => {
            infoWindow.setContent(`
            <div><span><b>${title || ''}</b></span></div>
            <p>${description || ''}</p>
        `)
            infoWindow.open({
                anchor: marker,
                map: googleMap,
                shouldFocus: true,
            })
        })

        googleMap.addListener('click', () => {
            infoWindow.close()
        })
    }, [coordinate.latitude, coordinate.longitude, description, infoWindow, googleMap, title])
    return (
        <div>
            {children}
        </div>
    )
}

export default ControlMapMarker
