import { BrowserMultiFormatReader, BarcodeFormat } from '@zxing/browser'
import { BarcodeType } from '@apphiveio/controlsmanager/types/RenderComponents'
import generateImageCanvasFromCamera from './generateImageCanvasFromCamera'

const readQRBarcodeFromCamera = (cameraElement: HTMLVideoElement) => {
    try {
        const generateImageCanvas = generateImageCanvasFromCamera(cameraElement)
        if (!generateImageCanvas) {
            return undefined
        }
        const { canvas, dimensions: { width, height } } = generateImageCanvas
        const reader = new BrowserMultiFormatReader()
        const code = reader.decodeFromCanvas(canvas)
        const data = code.getText()
        const type = BarcodeFormat[code.getBarcodeFormat()] as BarcodeType
        const origin = code.getResultPoints().map((point) => ({ x: point.getX(), y: point.getY() }))
        return {
            data,
            type,
            bounds: {
                width,
                height,
                origin,
            },
        }
    } catch {
        return undefined
    }
}

export default readQRBarcodeFromCamera
