import styled, { keyframes } from 'styled-components/macro'

export const Frame = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 480px) {
        justify-content: inherit;
    }
`

export const PreviewerFrame = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);
`

const ghostAnim = keyframes`
    0% { opacity:0; }
    5% { opacity:0.75 }
    10% { opacity:1 }
    82% { opacity:1 }
    83% { opacity:0.25 }
    95% { opacity:0 }
    100% { opacity:0 }
`

export const ToastWrapper = styled.div`
    & > .toast-item {
        position: absolute;
        z-index: 1000;
        color: #000;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        padding: 14px 21px;
        background-color: #e1dcefbf;
        border-radius: 20px;
        visibility: hidden;
        animation-delay: 0s;
        animation-timing-function: linear;
        width: max-content;
        max-width: calc(100% - 2rem);
        word-wrap: break-word;

        &.anim {
            visibility: visible;
            animation-name: ${ghostAnim};
        }

        // Duration
        &.long {
            animation-duration: 5s;
        }
        &.short {
            animation-duration: 3.3s;
        }

        // Position
        &.top {
            top: 1rem;
        }
        &.center {
            top: 50%;
            transform: translate(-50%, -50%);
        }
        &.bottom {
            bottom: 1rem;
        }
    }
`
