import React, { useRef, useEffect, useState } from 'react'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import ControlCameraViewer from '../ControlCameraViewer'
import generateImageFromCameraWithCanvas from '../../../shared/generateImageFromCameraWithCanvas'
import useScannerQRBarcodeFromCameraEffect from '../../../hooks/useScannerQRBarcodeFromCameraEffect'

const ControlCamera: AppAtomRenders['Camera'] = ({
    shouldTakeAPicture,
    shouldBeRecording,
    zoom,
    children,
    onTakePicture,
    onStartRecording,
    onStopRecording,
    onQRBarcodeRead,
}) => {
    const cameraRef = useRef<HTMLVideoElement>(null)
    const [cameraStream, setCameraStream] = useState<MediaStream>()
    const [isRecording, setIsRecording] = useState(false)

    useEffect(() => {
        const camera = cameraRef.current
        if (camera && cameraStream) {
            camera.srcObject = cameraStream
            camera.play()

            return () => {
                camera.pause()
                cameraStream?.getTracks().forEach((track) => track.stop())
            }
        }
        return undefined
    }, [cameraRef, cameraStream])

    useEffect(() => {
        if (cameraRef.current && shouldTakeAPicture && onTakePicture) {
            const uri = generateImageFromCameraWithCanvas({
                cameraElement: cameraRef.current,
                zoom,
            })
            if (uri) onTakePicture(uri)
        }
    }, [shouldTakeAPicture, onTakePicture, zoom])

    useEffect(() => {
        if (cameraRef.current && cameraStream) {
            const cameraElement = cameraRef.current
            cameraElement.muted = true
            const recordedChunks: BlobPart[] | undefined = []
            const mediaRecorder = new MediaRecorder(cameraStream, { mimeType: 'video/webm; codecs=vp9' })
            if (shouldBeRecording && !isRecording) {
                mediaRecorder.start()
                setIsRecording(true)
                mediaRecorder.ondataavailable = (event) => {
                    recordedChunks.push(event.data)
                    if (mediaRecorder.state === 'recording') {
                        mediaRecorder.stop()
                    }
                }
                mediaRecorder.onstop = () => {
                    const blob = new Blob(recordedChunks, { type: 'video/webm' })
                    const uri = URL.createObjectURL(blob)
                    if (uri) {
                        onStartRecording(uri)
                    }
                    setIsRecording(false)
                }
            }
            if (!shouldBeRecording && isRecording) {
                onStopRecording()
                setIsRecording(false)
            }
        }
    }, [shouldBeRecording, onStartRecording, isRecording, onStopRecording, cameraStream])

    useScannerQRBarcodeFromCameraEffect(cameraRef, onQRBarcodeRead)

    return (
        <ControlCameraViewer
            ref={cameraRef}
            setCameraStream={setCameraStream}
            zoom={zoom}
        >
            {children}
        </ControlCameraViewer>
    )
}

export default ControlCamera
