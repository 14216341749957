import { combineReducers } from 'redux'
import { ApplicationState, PreviewerState } from '@apphiveio/controlsmanager/types/Application'
import buildApplicationStateReducer from '@apphiveio/controlsmanager/applicationState/buildApplicationStateReducer'
import buildPreviewerStateReducer from '@apphiveio/controlsmanager/previewerState/buildPreviewerStateReducer'
import { initialAppState } from 'store/reducers/previewer'
import auth, { AuthState } from './auth'

export type ApphiveRendererState = {
    auth: AuthState;
    appState: ApplicationState;
    previewer: PreviewerState;
}

const rootReducer = combineReducers({
    auth,
    appState: buildApplicationStateReducer(initialAppState),
    previewer: buildPreviewerStateReducer(),
})

export default rootReducer
