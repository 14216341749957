import React, { useEffect, useMemo, useState } from 'react'
import LottieWrapper from 'rangel-react-lottie'
import defaultLottie from '@apphiveio/controlsmanager/ControlRenderer/components/LoadingPageRenderer/defaultLottie'
import getLottieFromUrl from '@apphiveio/controlsmanager/shared/getLottieFromUrl'

type Props = {
    src?: string;
    background?:string;
}

const ControlLottie: React.FC<Props> = ({
    src,
    background,
}) => {
    const [lottie, setLottie] = useState<unknown>(undefined)

    let backgroundStyle: React.CSSProperties = {}

    if (background) {
        backgroundStyle = {
            backgroundColor: background,
        }
    }

    useEffect(() => {
        if (!src) {
            setLottie(defaultLottie)
            return
        }

        getLottieFromUrl(src)
            .then(setLottie)
    }, [src])

    const lottieOptions = useMemo(() => ({
        loop: true,
        autoplay: true,
        animationData: lottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    } as const), [lottie])

    return (
        <LottieWrapper
            options={lottieOptions}
            height="100%"
            width="100%"
            style={backgroundStyle}
        />
    )
}

export default ControlLottie
