import React, { useEffect, useMemo, useState } from 'react'
import LottieWrapper from 'rangel-react-lottie'
import defaultLottie from '@apphiveio/controlsmanager/ControlRenderer/components/LoadingPageRenderer/defaultLottie'
import getLottieFromUrl from '@apphiveio/controlsmanager/shared/getLottieFromUrl'

type Props = {
    src?: string;
    source?: unknown;
    background?: string;
}

const ControlLottie: React.VFC<Props> = ({
    src,
    source,
    background,
}) => {
    const [lottie, setLottie] = useState<unknown>(undefined)

    let backgroundStyle: React.CSSProperties = {}

    if (background) {
        backgroundStyle = {
            backgroundColor: background,
        }
    }

    useEffect(() => {
        if (!src && !source) {
            setLottie(defaultLottie)
            return
        }
        if (src) {
            getLottieFromUrl(src)
                .then(setLottie)
        }
    }, [src, source])

    const lottieOptions = useMemo(() => ({
        loop: true,
        autoplay: true,
        animationData: (lottie || source),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    } as const), [lottie, source])

    if (!lottie && !source) {
        return null
    }

    return (
        <LottieWrapper
            options={lottieOptions}
            height="100%"
            width="100%"
            style={backgroundStyle}
        />
    )
}

export default ControlLottie
