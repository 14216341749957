import React, {
    useEffect,
    useRef,
    useMemo,
    useState,
} from 'react'
import { debouncedFunction } from '@apphiveio/controlsmanager/shared'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import useGoogleMapMarker from '../../../hooks/useGoogleMapMarker'
import ControlMapContext from './controlMapContext'

const convertionSets = [
    { cm: 0.007, we: 16 },
    { cm: 0.03, we: 13 },
    { cm: 0.2, we: 11 },
    { cm: 0.5, we: 9 },
    { cm: 1, we: 8 },
    { cm: 2, we: 7 },
    { cm: 3, we: 7 },
    { cm: 9, we: 5 },
    { cm: 20, we: 4 },
    { cm: 40, we: 3 },
    { cm: 80, we: 2 },
    { cm: 100, we: 1 },
]

const mapZoomFromWebToCm = (original: number) => {
    const withDifs = convertionSets.map((set) => ({
        ...set,
        diff: Math.abs(set.we - original),
    }))

    let currentLessDiff = Number.MAX_VALUE
    let choosedValue = 0.007

    withDifs.forEach((withDiff) => {
        if (withDiff.diff < currentLessDiff) {
            currentLessDiff = withDiff.diff
            choosedValue = withDiff.cm
        }
    })

    return choosedValue
}

const mapZoomFromCmToWeb = (original: number) => {
    const withDifs = convertionSets.map((set) => ({
        ...set,
        diff: Math.abs(set.cm - original),
    }))

    let currentLessDiff = Number.MAX_VALUE
    let choosedValue = 16

    withDifs.forEach((withDiff) => {
        if (withDiff.diff < currentLessDiff) {
            currentLessDiff = withDiff.diff
            choosedValue = withDiff.we
        }
    })

    return choosedValue
}

const ControlMapView: AppAtomRenders['MapView'] = ({
    showsUserLocation,
    region,
    zoom,
    style,
    children,
    pitchEnabled,
    onRegionChange,
    onRegionChangeComplete,
}) => {
    const [googleMap, setGoogleMap] = useState<google.maps.Map | undefined>(undefined)
    const divRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (divRef.current) {
            setGoogleMap(new window.google.maps.Map(divRef.current, {
                disableDefaultUI: true,
                center: {
                    lat: 19.2573425,
                    lng: -99.5799814,
                },
                zoom: 15,
            }))
        }
    }, [])

    const { addMarker } = useGoogleMapMarker(googleMap)

    const controlMapContext = useMemo(() => {
        const infoWindow = new google.maps.InfoWindow()
        return {
            googleMap,
            infoWindow,
        }
    }, [googleMap])

    useEffect(() => {
        if (!googleMap) {
            return
        }
        googleMap.setCenter({
            lat: region.latitude,
            lng: region.longitude,
        })
    }, [googleMap, region])

    useEffect(() => {
        if (!googleMap) {
            return
        }
        googleMap.setZoom(mapZoomFromCmToWeb(zoom))
    }, [googleMap, zoom])

    useEffect(() => {
        if (!googleMap) {
            return undefined
        }

        const getLocation = () => {
            const center = googleMap.getCenter()

            return {
                latitude: center?.lat() || 0,
                longitude: center?.lng() || 0,
            }
        }

        const addMapListener = (
            eventName: 'center_changed' | 'idle',
            callback: () => void,
        ) => window.google.maps.event.addListener(googleMap, eventName, callback)

        const reportCompleted = debouncedFunction(500, () => {
            if (onRegionChangeComplete) {
                const location = getLocation()
                onRegionChangeComplete({
                    latitude: location.latitude,
                    longitude: location.longitude,
                }, mapZoomFromWebToCm(googleMap.getZoom() || 0.2))
            }
        })

        const centerChangedListener = addMapListener('center_changed', () => {
            if (onRegionChange) {
                const location = getLocation()
                onRegionChange({
                    latitude: location.latitude,
                    longitude: location.longitude,
                })
            }
            reportCompleted()
        })

        return () => {
            centerChangedListener.remove()
        }
    }, [googleMap, onRegionChange, onRegionChangeComplete])

    useEffect(() => {
        if (showsUserLocation) {
            navigator
                .geolocation
                .getCurrentPosition(
                    ({ coords: coordinate }) => {
                        addMarker({
                            coordinate,
                            icon: 'https://firebasestorage.googleapis.com/v0/b/odcreator-3acbb.appspot.com/o/marker%20(1).png?alt=media&token=a06d4b88-d21c-4fd4-ac7d-1b8d243f4882',
                            title: 'Current Location',
                        })
                    },
                )
        }
    }, [showsUserLocation, addMarker])

    useEffect(() => {
        if (googleMap) {
            googleMap.setOptions({
                draggable: pitchEnabled,
            })
        }
    }, [googleMap, pitchEnabled])

    return (
        <ControlMapContext.Provider value={controlMapContext}>
            <div ref={divRef} id="map" style={style}>
                {children}
            </div>
        </ControlMapContext.Provider>
    )
}

export default ControlMapView
