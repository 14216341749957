import store from 'store'
import { buildAppOverwriteDbCacheAction } from '@apphiveio/controlsmanager/applicationState/applicationStateActionBuilders'
import { AppOverwriteDbCacheAction } from '@apphiveio/controlsmanager/applicationState/applicationStateActions'
import ReduxConstants from 'store/constants'
import { AppUser } from '@apphiveio/controlsmanager/types/Firebase'

export const appOverwriteDbCache = (
    payload: AppOverwriteDbCacheAction['payload'],
) => {
    store.dispatch(buildAppOverwriteDbCacheAction(payload))
}

export const setRendererCurrentUser = (user: AppUser | null) => {
    store.dispatch({
        type: ReduxConstants.SET_RENDERER_CURRENT_USER,
        payload: user,
    })
}
