import React, {
    useRef, useEffect, useState, useCallback, useMemo,
} from 'react'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import ControlCameraViewer from '../ControlCameraViewer'
import generateImageFromCameraWithCanvas from '../../../shared/generateImageFromCameraWithCanvas'
import useScannerQRBarcodeFromCameraEffect from '../../../hooks/useScannerQRBarcodeFromCameraEffect'

const ControlCameraView: AppAtomRenders['CameraView'] = ({
    style,
    takePhoto,
    startRecord,
    stopRecord,
    onTakePicture,
    onStartRecord,
    onStopRecord,
    onQRBarcodeRead,
}) => {
    const cameraRef = useRef<HTMLVideoElement>(null)
    const [cameraStream, setCameraStream] = useState<MediaStream>()
    const [fileSrc, setFile] = useState('')

    const mediaRecorder = useMemo(() => {
        if (cameraStream) {
            const mediaRecorder = new MediaRecorder(cameraStream, { mimeType: 'video/webm' })
            let recordedChunks: Blob[] = []

            mediaRecorder.ondataavailable = (e) => {
                recordedChunks.push(e.data)
            }

            mediaRecorder.onstart = () => {
                recordedChunks = []
            }

            mediaRecorder.onstop = () => {
                const uri = URL.createObjectURL(new Blob(recordedChunks, { type: 'video/mp4' }))
                setFile(uri)
            }

            return mediaRecorder
        }
        return undefined
    }, [cameraStream])

    const takePictureCallBack = useCallback(async () => {
        if (cameraRef.current && onTakePicture) {
            const uri = generateImageFromCameraWithCanvas({
                cameraElement: cameraRef.current,
            })
            if (uri) onTakePicture(uri)
        }
    }, [onTakePicture])

    const startRecordCallBack = useCallback(async () => {
        if (mediaRecorder) {
            const { state } = mediaRecorder
            if (state === 'inactive') {
                mediaRecorder.start()
            } else if (state === 'paused') {
                mediaRecorder.resume()
            }
        }
    }, [mediaRecorder])

    const stopRecordCallBack = useCallback(async () => {
        if (mediaRecorder) {
            const { state } = mediaRecorder
            if (state === 'recording') {
                mediaRecorder.stop()
                if (onStartRecord) onStartRecord(fileSrc)
                if (onStopRecord) onStopRecord()
            }
        }
    }, [fileSrc, mediaRecorder, onStartRecord, onStopRecord])

    useEffect(() => {
        if (cameraRef.current && cameraStream) {
            cameraRef.current.srcObject = cameraStream
            cameraRef.current.play()
        }
    }, [cameraRef, cameraStream])

    useEffect(() => {
        if (takePhoto) {
            takePictureCallBack()
        }
    }, [takePhoto, takePictureCallBack])

    useEffect(() => {
        if (startRecord) {
            startRecordCallBack()
        }
        if (stopRecord) {
            stopRecordCallBack()
        }
    }, [
        fileSrc,
        onStartRecord,
        onStopRecord,
        startRecord,
        startRecordCallBack,
        stopRecord,
        stopRecordCallBack,
    ])

    useScannerQRBarcodeFromCameraEffect(cameraRef, onQRBarcodeRead)

    return (
        <ControlCameraViewer
            width={style?.width}
            height={style?.height}
            ref={cameraRef}
            setCameraStream={setCameraStream}
        />
    )
}

export default ControlCameraView
