/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    '6MpUJR3v3VHer2Y1n8usqu.png': { uri: './images/6MpUJR3v3VHer2Y1n8usqu.png' }
}

export default imageStaticSourcesByFileName
