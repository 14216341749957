import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/messaging'
import clientconfig from './clientconfig'

export const getFirebaseApp = (
    appName: string,
) => {
    try {
        return firebase.app(appName)
    } catch {
        const firebaseConfig = clientconfig.getFirebaseWebClient()
        if (!firebaseConfig) {
            throw new Error('Should always have a firebase config')
        }
        return firebase.initializeApp(firebaseConfig, appName)
    }
}

export const odCreatorApp = getFirebaseApp('main')
