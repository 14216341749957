import { Callable } from '@apphiveio/controlsmanager/types/Runtime'

const callableNotSupported: Callable = ({
    processor,
}) => {
    processor.root.alert(
        'Not supported',
        'This function is not supported in the web app',
    )
}

export default callableNotSupported
