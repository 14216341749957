import AppRenderer from '@apphiveio/controlsmanager/AppRenderer'
import WebappContext from 'components/organisms/WebappContext'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApphiveRendererState } from 'store/reducers'
import { BuildOrders } from '@apphiveio/controlsmanager/types/Controls'
import useWindowResizeEffect from 'hooks/useWindowResizeEffect'
import {
    Frame,
    PreviewerFrame,
    ToastWrapper,
} from './style'

type ScreenDimentions = {
    width: number;
    height: number;
}

type Props = {
    appId: string;
    buildOrders: BuildOrders;
}

const AppBooter: React.VFC<Props> = ({ appId, buildOrders }) => {
    const [dimentions, setDimentions] = useState<ScreenDimentions | undefined>(undefined)
    const routeStack = useSelector((state: ApphiveRendererState) => state.appState.routeStack)
    const loadingPageContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.loadingPageContext)
    const bottomMenuContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.bottomMenuContext)
    const sideMenuContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.sideMenuContext)
    const alertContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.openAlert)
    const confirmAlertContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.openConfirmAlert)
    const inputDialogContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.openInputDialog)
    const cameraContext = useSelector((
        state: ApphiveRendererState,
    ) => state.appState.openCameraParams)

    const calculateSize = useCallback(() => {
        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight

        if (windowWidth > windowHeight) {
            setDimentions({
                width: 339,
                height: 639,
            })
        } else {
            setDimentions({
                width: windowWidth,
                height: windowHeight,
            })
        }
    }, [])

    useWindowResizeEffect(calculateSize)

    if (!dimentions) {
        return null
    }

    return (
        <Frame>
            <PreviewerFrame>
                <WebappContext
                    windowHeight={dimentions.height}
                    windowWidth={dimentions.width}
                    appId={appId}
                    buildOrdersFunctions={buildOrders.functions}
                    buildOrders={buildOrders}
                >
                    <AppRenderer
                        buildOrders={buildOrders}
                        routeStack={routeStack}
                        loadingPageContext={loadingPageContext}
                        sideMenuContext={sideMenuContext}
                        bottomMenuContext={bottomMenuContext}
                        alertContext={alertContext}
                        cameraContext={cameraContext}
                        confirmAlertContext={confirmAlertContext}
                        inputDialogContext={inputDialogContext}
                    />
                    <ToastWrapper id="toast-container" />
                </WebappContext>
            </PreviewerFrame>
        </Frame>
    )
}

export default AppBooter
