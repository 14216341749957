import React from 'react'
import AppBooter from 'components/organisms/AppBooter'
import { useSelector } from 'react-redux'
import { ApphiveRendererState } from 'store/reducers'
import PreviewerPageSplash from '@apphiveio/controlsmanager/components/PreviewerPageSplash'

const ProcessorAppBooter = () => {
    const projectId = useSelector((state: ApphiveRendererState) => state.appState.projectId)
    const appId = useSelector((state: ApphiveRendererState) => state.appState.appId)
    const buildOrders = useSelector((state: ApphiveRendererState) => state.appState.buildOrders)

    if (!projectId || !appId || !buildOrders) {
        return <PreviewerPageSplash message="Loading app" />
    }

    return (
        <AppBooter appId={appId} buildOrders={buildOrders} />
    )
}

export default ProcessorAppBooter
