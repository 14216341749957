import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import store from 'store'
import { ApolloProvider } from '@apollo/client'
import apolloClient from 'providers/apolloClient'
import PreviewerFrame from 'components/atoms/PreviewerFrame'
import MobileFrame from 'components/atoms/MobileFrame'
import ProcessorAppBooter from 'components/organisms/ProcessorAppBooter'
import PreviewerAppContext from 'components/organisms/PreviewerAppContext'

const App = () => (
    <ApolloProvider client={apolloClient}>
        <ReduxProvider store={store}>
            <PreviewerAppContext>
                <MobileFrame>
                    <PreviewerFrame>
                        <ProcessorAppBooter />
                    </PreviewerFrame>
                </MobileFrame>
            </PreviewerAppContext>
        </ReduxProvider>
    </ApolloProvider>
)

export default App
