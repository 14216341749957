import type firebase from 'firebase/app'
import clientconfig from 'clientconfig'

const firebaseGetFunctions = (firebaseApp: firebase.app.App) => {
    const environment = clientconfig.getEnviroment()

    const functions = firebaseApp.functions()

    if (environment === 'DEVELOPMENT') {
        functions.useEmulator('localhost', 5001)
    }

    return functions
}

export default firebaseGetFunctions
